import { CheckSquareTwoTone, CloseSquareTwoTone } from "@ant-design/icons";
import { Badge, Popover, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import IntlMessage from "components/util-components/IntlMessage";
import React, { useEffect, useState } from "react";
import EcosystemService from "services/EcosystemService";

type Props = {};
type ApisData = { [key: string]: boolean };

const DownMonitorBadge = (props: Props) => {
  const [apisData, setApisData] = useState<ApisData | undefined>();
  const [fetching, setFetching] = useState(false);

  const fetchApiIntegrity = async () => {
    try {
      setFetching(true);
      const response = await EcosystemService.getApisIntegrity();
      setApisData(response);
    } finally {
      setFetching(false);
    }
  };

  const getBadgeColor = (apisData: ApisData | undefined) => {
    if (!apisData) return undefined;

    const allAreTrue = Object.values(apisData).reduce((v1, v2) => v1 && v2, true);
    if (allAreTrue) return "green";

    const allAreFalse = Object.values(apisData).reduce((v1, v2) => v1 || v2, false);
    if (allAreFalse) return "red";

    return "yellow";
  };

  const renderApiPopover = (apisData: ApisData | undefined) => {
    if (!apisData) return <IntlMessage id="loadingLabel" />;

    const columns: ColumnsType<any> = [
      {
        key: "uri",
        dataIndex: "uri",
      },
      {
        key: "status",
        dataIndex: "status",
        align: "center",
        render: (status) =>
          status ? (
            <CheckSquareTwoTone twoToneColor="green" />
          ) : (
            <CloseSquareTwoTone twoToneColor="red" />
          ),
      },
    ];

    const apis = Object.keys(apisData);
    const dataSource = apis.map((uri) => ({
      uri,
      status: apisData[uri],
    }));

    return (
      <Table columns={columns} dataSource={dataSource} pagination={false} showHeader={false} />
    );
  };

  useEffect(() => {
    fetchApiIntegrity();
    const interval = setInterval(() => fetchApiIntegrity(), 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Popover content={renderApiPopover(apisData)} title={<IntlMessage id="downMonitor.title" />}>
      <Badge
        dot
        showZero
        size="default"
        status={fetching ? "processing" : "success"}
        color={getBadgeColor(apisData)}
      />
    </Popover>
  );
};

export default DownMonitorBadge;
