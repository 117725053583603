import { DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Raid Hut Admin";
export const API_BASE_URL = env.API_ENDPOINT_LAMBDA;
export const API_RAIDMACHINE_URL = env.API_ENDPOINT_RAIDMACHINE;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const GAME_SELECTION_PREFIX_PATH = "/game-selection";
export const KEY = "lkjdf25lksdfjX90FLAKS1glkjdf4";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "br",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "dark",
  direction: DIR_LTR,
};
