import fetch from "auth/FetchInterceptor";

const EcosystemService = {
  getGames: function (): any {
    return fetch({
      url: "/",
      method: "get",
    });
  },

  getApisIntegrity: function (): any {
    return fetch({
      url: "/apis",
      method: "get",
    });
  },
};

export default EcosystemService;
