import { Game } from "model/ecosystem";
import { SELECT_GAME, UNSELECT_GAME } from "redux/constants/Game";

export const selectGame = (game: Game) => {
  return {
    type: SELECT_GAME,
    game,
  };
};

export const unselectGame = () => {
  return {
    type: UNSELECT_GAME,
  };
};
