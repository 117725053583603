import { APP_PREFIX_PATH, GAME_SELECTION_PREFIX_PATH } from "configs/AppConfig";
import { Game } from "model/ecosystem";
import { Action } from "redux";
import { SELECT_GAME, UNSELECT_GAME } from "redux/constants/Game";

type GameState = {
  selectedGame?: Game;
  redirect: string;
};

type GameAction = Action<string> & {
  game: Game;
};

const initState: GameState = {
  selectedGame: undefined,
  redirect: "",
};

const game = (state = initState, action: GameAction) => {
  switch (action.type) {
    case SELECT_GAME:
      return {
        ...state,
        selectedGame: action.game,
        redirect: APP_PREFIX_PATH,
      };
    case UNSELECT_GAME:
      return {
        ...state,
        selectedGame: undefined,
        redirect: GAME_SELECTION_PREFIX_PATH,
      };
    default:
      return state;
  }
};

export default game;
