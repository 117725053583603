import { auth } from "auth/FirebaseAuth";
import firebase from "firebase/app";

const FirebaseService = {
  signInEmailWithPasswordRequest: (email: string, password: string) =>
    auth.signInWithEmailAndPassword(email, password),

  signInEmailWithLinkRequest: (email: string, redirectUrl: string) => {
    const settings: firebase.auth.ActionCodeSettings = { url: redirectUrl, handleCodeInApp: true };
    return auth.sendSignInLinkToEmail(email, settings);
  },

  signOutRequest: () => auth.signOut().catch((err) => err),

  currentUser: () => auth.currentUser,
};

// FirebaseService.signInGoogleRequest = async () =>
//   await auth.signInWithPopup(googleAuthProvider).then(user => user).catch(err => err);

// FirebaseService.signInFacebookRequest = async () =>
//   await auth.signInWithPopup(facebookAuthProvider).then(user => user).catch(err => err);

// FirebaseService.signUpEmailRequest = async (email: string, password: string) =>
// 	await auth.createUserWithEmailAndPassword(email, password).then(user => user).catch(err => err);

export default FirebaseService;
