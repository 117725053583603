import { Select } from "antd";
import { Game } from "model/ecosystem";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectGame } from "redux/actions/Game";
import gameService from "services/EcosystemService";

type Props = {
  selectedGame: Game;
  user: firebase.default.User;
  selectGame: (g: Game) => void;
};

const GameSelector = (props: Props) => {
  const { selectedGame, selectGame } = props;
  const [games, setGames] = useState<Game[]>([]);

  useEffect(() => {
    if (!selectedGame) {
      if (games.length > 0) {
        selectGame(games[0]);
      }
    }
  }, [games, selectedGame, selectGame]);

  useEffect(() => {
    gameService.getGames().then(setGames);
  }, [selectGame]);

  const fetchAndSelectGame = (gameId: string) => {
    const gameToSelect = games.find((elm) => elm.id === gameId);
    if (gameToSelect) {
      selectGame(gameToSelect);
    }
  };

  if (games.length === 0 || !selectedGame) return null;

  return (
    <Select defaultValue={selectedGame.id} onChange={fetchAndSelectGame}>
      {games.map((game) => (
        <Select.Option key={game.id} value={game.id}>
          {game.name}
        </Select.Option>
      ))}
    </Select>
  );
};

const mapStateToProps = ({ game }: any) => {
  const { selectedGame } = game;
  return { selectedGame };
};

const mapDispatchToProps = {
  selectGame,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameSelector);
