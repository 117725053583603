import { Grid } from "antd";
import { APP_NAME } from "configs/AppConfig";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import React from "react";
import { connect } from "react-redux";
import utils from "utils";

type Props = {
  navCollapsed: boolean;
  navType: string;
  logoType: string;
  mobileLogo: boolean;
};

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props: Props, isMobile: boolean) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return "auto";
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

const getLogo = (props: Props) => {
  const { navCollapsed, logoType } = props;
  if (logoType === "light") {
    if (navCollapsed) {
      return "/img/rh-logo-sm.png";
    }
    return "/img/rh-logo.png";
  }

  if (navCollapsed) {
    return "/img/rh-logo-sm.png";
  }
  return "/img/rh-logo.png";
};

const getLogoDisplay = (isMobile: boolean, mobileLogo: boolean) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  } else {
    return "logo";
  }
};

export const Logo = (props: Props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <img src={getLogo(props)} alt={`${APP_NAME} logo`} />
    </div>
  );
};

const mapStateToProps = ({ theme }: any) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
