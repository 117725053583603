import { BulbFilled, BulbOutlined, LogoutOutlined } from "@ant-design/icons";
import { Menu, Switch } from "antd";
import React, { useCallback, useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { connect } from "react-redux";
import { onSwitchTheme } from "redux/actions";
import { signOut } from "redux/actions/Auth";

type Props = {
  direction: string;
  currentTheme: string;
  onSwitchTheme: (theme: string) => void;
  signOut: () => void;
};

const NavPanel = (props: Props) => {
  const { switcher } = useThemeSwitcher();

  const switchTheme = useCallback(
    (theme: string) => {
      props.onSwitchTheme(theme);
      switcher({ theme });
      window.localStorage.setItem("theme", theme);
    },
    [props, switcher]
  );

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");
    if (theme) {
      switchTheme(theme);
    }
  }, [switchTheme]);

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item key="theme-switcher">
          <Switch
            className="nav-icon mb-1"
            checkedChildren={<BulbFilled style={{ fontSize: "14px", color: "white" }} />}
            unCheckedChildren={<BulbOutlined style={{ fontSize: "14px", color: "black" }} />}
            checked={props.currentTheme === "dark"}
            onChange={(checked) => {
              const theme = checked ? "dark" : "light";
              switchTheme(theme);
            }}
          />
        </Menu.Item>
        <Menu.Item onClick={props.signOut} key="logout-button">
          <LogoutOutlined className="nav-icon mr-0" />
        </Menu.Item>
      </Menu>
      {/* <Drawer
          title="Theme Config"
          placement={props.direction === DIR_RTL ? "left" : "right"}
          width={350}
          onClose={s.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer> */}
    </>
  );
};

const mapStateToProps = ({ theme }: any) => {
  const { locale, currentTheme } = theme;
  return { locale, currentTheme };
};

const mapDispatchToProps = {
  signOut,
  onSwitchTheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanel);
