import { combineReducers } from "redux";
import Auth from "./Auth";
import Game from "./Game";
import Theme from "./Theme";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  game: Game,
});

export default reducers;
