import { Layout } from "antd";
import {
  NAV_TYPE_SIDE,
  SIDE_NAV_DARK,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import MenuContent from "./MenuContent";

const { Sider } = Layout;

type Props = {
  navCollapsed: boolean;
  sideNavTheme: string;
  routeInfo: string;
  hideGroupTitle: string;
  localization: string;
};

export const SideNav = (props: Props) => {
  const { sideNavTheme, navCollapsed } = props;

  return (
    <Sider
      className={`side-nav ${
        sideNavTheme === SIDE_NAV_DARK ? "side-nav-dark" : ""
      }`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
      style={{ paddingTop: 10 }}
    >
      <Scrollbars autoHide>
        <MenuContent type={NAV_TYPE_SIDE} {...props} />
      </Scrollbars>
    </Sider>
  );
};

const mapStateToProps = ({ theme }: any) => {
  const { navCollapsed, sideNavTheme, locale } = theme;
  return { navCollapsed, sideNavTheme, localization: locale };
};

export default connect(mapStateToProps)(SideNav);
