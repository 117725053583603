const dev = {
  API_ENDPOINT_LAMBDA: "http://localhost:5000",
  API_ENDPOINT_RAIDMACHINE: "https://localhost:7230/api"
};

const prod = {
  API_ENDPOINT_LAMBDA: "https://7kvuihrdxibvgkjtv6x4yll6pe0hootj.lambda-url.us-east-1.on.aws",
  API_ENDPOINT_RAIDMACHINE: "https://goiabada.tapiocahut.com/api"
};

const test = {
  API_ENDPOINT_LAMBDA: "https://api.test.com",
  API_ENDPOINT_RAIDMACHINE: "https://goiabada.tapiocahut.com/api"
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
