import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import firebase from "firebase/app";
import "firebase/auth";
import useBodyClass from "hooks/useBodyClass";
import AppLocale from "lang";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { authenticated, signOutSuccess } from "redux/actions/Auth";

function RouteInterceptor({ children, condition, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        condition ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, location, direction, isAuthenticated, addUser, removeUser } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        addUser(user);
      } else {
        removeUser();
      }
    });
  });

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} condition={isAuthenticated}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { user } = auth;

  const isAuthenticated = user !== undefined;

  return { locale, direction, isAuthenticated };
};

const mapDispatchToProps = {
  addUser: authenticated,
  removeUser: signOutSuccess,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));
