const FirebaseConfig = {
  apiKey: "AIzaSyB2aR0aVgcbp6kO9zeCpPNRYsjRNoRDhFI",
  authDomain: "raidmachine-71cec.firebaseapp.com",
  projectId: "raidmachine-71cec",
  storageBucket: "raidmachine-71cec.appspot.com",
  messagingSenderId: "652748101810",
  appId: "1:652748101810:web:f92f5da1ae6d3d43d291ab",
  measurementId: "G-ZSCYJL7Q1W"
};

export default FirebaseConfig

