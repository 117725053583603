import { notification } from "antd";
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import qs from "qs";
import history from "../history";
import store from "../redux/store";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
//const PUBLIC_REQUEST_KEY = "public-request";

const errorCodeMessages = {
  204: "Não encontrado",
  401: "Falha na autenticação",
  400: "Falha na requisição",
  404: "Inexistente",
  500: "Erro no servidor",
  508: "Estouro de tempo limite",
};

function selectUser(state) {
  return state.auth.user;
}

// API Request interceptor
service.interceptors.request.use(
  async (config) => {
    const user = selectUser(store.getState());
    const jwtToken = await user?.getIdToken();

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    }

    config.paramsSerializer = (params) => {
      return qs.stringify(params);
    };

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "",
    };

    notificationParam.message = error.response
      ? errorCodeMessages[error.response.status]
      : "Servidor indisponível.";

    // Remove token and redirect
    if (error.response?.status === 401) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    if (notificationParam.message) notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
